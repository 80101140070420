import React, { useState, useEffect } from "react"
import * as turf from "@turf/turf"

import { IconType } from "@pag/center/components/icon/iconType"
import Icon from "@pag/center/components/icon/Icon"
import RouteGuidanceInfo from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/routeGuidance/routeGuidanceInfo/RouteGuidanceInfo"
import Button from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/routeGuidance/button/Button"
import MapTooltipAddressItem from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/poi/mapTooltipAddressItem/MapTooltipAddressItem"
import MapTooltip from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/poi/mapTooltip/MapTooltip"
import PoiStack from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/poi/poiStack/PoiStack"
import MapTooltipExpanded from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/poi/mapTooltipExpanded/MapTooltipExpanded"
import MapTooltipAddressItemDetails from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/poi/mapTooltipAddressItemDetails/MapTooltipAddressItemDetails"
import MapTooltipDetailButtons from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/poi/mapTooltipDetailButtons/MapTooltipDetailButtons"
import PoiStackList from "./poi/poiStackList/PoiStackList"

import { ManeuverType } from "@pag/center/views/navigationScreen/mapMain/types"
import { PoiItem } from "./poi/types"

import "./navigationOverlay.scss"
import NavigationService from "@pag/utils/navigationService/NavigationService"

export enum ENavigationOverlay {
  LOCATION_SELECTED = "LocationSelected",
  DESTINATION_SELECTED = "DestinationSelected",
  POI_STACK_SELECTED = "PoiStackSelected",
  GUIDANCE_ACTIVE = "GuidanceActive",
  TRAFFIC_SELECTED = "TrafficSelected"
}

interface INavigationOverlayProps {
  currentPosition: [number, number]
  nextManeuver: ManeuverType
  isGuidanceActive: boolean
  overlay: string
  activePoiItemIndex: number | undefined
  poiStackListVisible: boolean
  showAlternativeRoutes: boolean
  isDestinationInputActive: boolean
  stopNavigationAction: () => void
  changeNavigationOverlayAction?: (payload: string) => void
  setActivePoiItemIndex?: (payload: number | undefined) => void
  updatePoiStackListVisibleAction: (payload: boolean) => void
}

const NavigationOverlay: React.FC<INavigationOverlayProps> = (props) => {
  // const [poiStackListVisible, setPoiStackListVisible] = useState(false) // TODO
  const [selectedLocationExpanded] = useState(false) // TODO
  const poiStackList = NavigationService.getActiveConfig().poiStackList

  const poiStackIcons: Set<string> = new Set(poiStackList.map((poi: PoiItem) => poi.icon))

  useEffect(() => {
    for (let i = 0; i < poiStackList.length; i += 1) {
      poiStackList[i].distance = Math.round(
        turf.distance(props.currentPosition, poiStackList[i].location.coordinates) * 1000
      )
    }
  }, [props.currentPosition]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderPoiStack = () => {
    return (
      <React.Fragment>
        {!props.poiStackListVisible && !selectedLocationExpanded && props.activePoiItemIndex !== undefined && (
          <MapTooltip className="tool-tip tool-tip--collapsed poi-stack--selected">
            <MapTooltipAddressItem
              // className="address-item" // TODO
              address={poiStackList[props.activePoiItemIndex].location}
              distance={poiStackList[props.activePoiItemIndex].distance}
              onPress={() => {}} // TODO:
            />
            <Icon
              iconClass="toggle-details-button"
              iconType={IconType.icon_options_more}
              onPress={() => props.updatePoiStackListVisibleAction(true)}
            />
          </MapTooltip>
        )}
        {!props.poiStackListVisible && !selectedLocationExpanded && poiStackIcons.size > 0 && (
          <PoiStack
            className="tool-tip tool-tip--poi-stack"
            amount={poiStackIcons.size}
            icons={poiStackIcons}
            showPoiStackList={() => props.updatePoiStackListVisibleAction(true)}
          />
        )}
        {(props.poiStackListVisible || selectedLocationExpanded) && props.activePoiItemIndex !== undefined && (
          <MapTooltipExpanded
            className="tool-tip tool-tip--expanded poi-stack--expanded"
            content={
              props.poiStackListVisible ? (
                <PoiStackList
                  className="poi-stack-list"
                  poiStackList={poiStackList}
                  onTap={(idx: number) => props.setActivePoiItemIndex && props.setActivePoiItemIndex(idx)}
                  onTapEnd={() => {}} // TODO:
                />
              ) : (
                <React.Fragment>
                  <MapTooltipAddressItemDetails
                    className="address-item--expanded"
                    overlay={ENavigationOverlay.POI_STACK_SELECTED}
                    location={poiStackList[props.activePoiItemIndex].location}
                    // isFavorite={false}
                  />
                  <MapTooltipDetailButtons />
                </React.Fragment>
              )
            }
            icon={
              <Icon
                iconClass="cancel-button"
                iconType={IconType.icon_cancel}
                onPress={() => {
                  props.changeNavigationOverlayAction &&
                    props.changeNavigationOverlayAction(ENavigationOverlay.GUIDANCE_ACTIVE)

                  props.setActivePoiItemIndex && props.setActivePoiItemIndex(undefined)
                  props.updatePoiStackListVisibleAction && props.updatePoiStackListVisibleAction(false)
                }}
              />
            }
          />
        )}
      </React.Fragment>
    )
  }

  const renderRouteGuidance = () => (
    <div className="navigation__wrapper">
      <RouteGuidanceInfo
        className="guidance-active-next-maneuver tool-tip tool-tip--next-maneuver tool-tip--small"
        maneuver={props.nextManeuver}
        followEvent={props.nextManeuver}
        laneGuidance={[]}
        currentPosition={props.currentPosition}
      />
      <Button
        className="guidance-active__button tool-tip__button button--call-to-action"
        labelText="Stop"
        onClick={props.stopNavigationAction}
      />
      {/**
        //                   <pag3-second-guidance-info *ngIf="secondManeuver && followEvent?.showManeuver"
        //                              class="guidance-active-second-maneuver tool-tip tool-tip--second-maneuver"
        //                              id="RouteGuidanceInfoSecondManeuver"
        //                              [ngClass]="{'maneuver__override-position': laneGuidance && laneGuidance.length}"
        //                              [maneuver]="secondManeuver"
        //   ></pag3-second-guidance-info>  
      */}
    </div>
  )

  let content: React.ReactNode = null
  switch (props.overlay) {
    case ENavigationOverlay.LOCATION_SELECTED:
      break
    case ENavigationOverlay.DESTINATION_SELECTED:
      break
    case ENavigationOverlay.GUIDANCE_ACTIVE:
      content =
        props.isGuidanceActive && !props.showAlternativeRoutes && !props.isDestinationInputActive
          ? renderRouteGuidance()
          : null
      break
    case ENavigationOverlay.POI_STACK_SELECTED:
      content = renderPoiStack()
      break
    case ENavigationOverlay.TRAFFIC_SELECTED:
      break
    default:
      content =
        props.isGuidanceActive && !props.showAlternativeRoutes && !props.isDestinationInputActive
          ? renderRouteGuidance()
          : null
  }

  return content !== null ? <div className="navigation-overlay-wrapper">{content}</div> : null
}

export default React.memo(NavigationOverlay)
