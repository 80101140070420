export const SET_SETTINGS_INITIAL_LOCATION = "SET_SETTINGS_INITIAL_LOCATION"
export const SET_SETTINGS_OPEN_STATE = "SET_SETTINGS_OPEN_STATE"
export const EMPTY_ROUTE = "#"
export const SET_PHONESWITCH_OPEN_STATE = "SET_PHONESWITCH_OPEN_STATE"

export type ReduxInitAction = { type: "@@INIT" }

interface SetInitialLocation {
  type: typeof SET_SETTINGS_INITIAL_LOCATION
  payload: string
}

interface SetOpenState {
  type: typeof SET_SETTINGS_OPEN_STATE
  isOpen: boolean
}

export interface StatusBarSettingsState {
  initialLocation: string
  isOpen: boolean
  PhoneSwitchOpen: boolean
}

export interface PhoneSwitchOpenState {
  type: typeof SET_PHONESWITCH_OPEN_STATE
  PhoneSwitchOpen: boolean
}

export interface StatusBarItem {
  icon: string
  func: string
}

export type StatusSettingsType = SetInitialLocation | SetOpenState | PhoneSwitchOpenState
