import {
  Action,
  MediaState,
  ADD_MEDIA_FAVOURITE,
  TOGGLE_MEDIA_PLAYVIEW,
  TOGGLE_APPLEID_POPUP,
  TOGGLE_LYRICS_DUMMY,
  TOGGLE_OPTIONS_SLIDEOUT,
  TOGGLE_SIRIUS_XM_POPUP,
  SHOW_SIRIUS_XM_WELCOMESCREEN
} from "./types"

const mediaInitialState: MediaState = {
  favourites: [{ name: "FM 4", cover: "fm4" }],
  isPlayviewVisible: false,
  appleIdPopUpVisible: false,
  isLyricsDummyVisible: false,
  isOptionsSlideOutVisible: false,
  siriusXmPopUpVisible: false,
  siriusXmWelcomeScreenVisible: true
}

export function mediaReducer(state = mediaInitialState, action: Action) {
  switch (action.type) {
    case ADD_MEDIA_FAVOURITE:
      if (state.favourites.filter((fav) => fav.name === action.favourite.name).length > 0) return { ...state }
      else return { ...state, favourites: [...state.favourites, action.favourite] }
    case TOGGLE_MEDIA_PLAYVIEW:
      return { ...state, isPlayviewVisible: !state.isPlayviewVisible }
    case TOGGLE_APPLEID_POPUP:
      return { ...state, appleIdPopUpVisible: action.visible }
    case TOGGLE_LYRICS_DUMMY:
      return { ...state, isLyricsDummyVisible: !state.isLyricsDummyVisible }
    case TOGGLE_OPTIONS_SLIDEOUT:
      return { ...state, isOptionsSlideOutVisible: !state.isOptionsSlideOutVisible }
    case TOGGLE_SIRIUS_XM_POPUP:
      return { ...state, siriusXmPopUpVisible: action.visible }
    case SHOW_SIRIUS_XM_WELCOMESCREEN:
      return { ...state, siriusXmWelcomeScreenVisible: action.visible }
    default:
      return state
  }
}
