export const UPDATE_LAUNCHER_TILE_ORDER = "UPDATE_LAUNCHER_TILE_ORDER"
export const SHOW_SUGGESTIONS = "SHOW_SUGGESTIONS"

export type LauncherTileState = {
  tiles: LauncherTile[]
  showSuggestions: boolean
}

export type LauncherSuggestionsAction = {
  type: typeof SHOW_SUGGESTIONS
  payload: boolean
}

export type LauncherTile = {
  text: string
  url?: string
  iconType: string
  settings_list?: any
}

export type updateLauncherTileOrderAction = {
  type: typeof UPDATE_LAUNCHER_TILE_ORDER
  payload: {
    tiles: LauncherTile[]
  }
}
export type LauncherTileActionType = updateLauncherTileOrderAction | LauncherSuggestionsAction
