import * as React from "react"
import ReactDOM from "react-dom"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { AppState } from "../components/redux/reducer"
import { SET_AUTHORIZED } from "../components/redux/types/mainState/types"
import { GetUserInfo, RenewCookie } from "./BackEndMethods"
import { BaseClient } from "./BaseClient"
import { imgBackEnd } from "./ImgConfig"
import LoginKeyPad from "./LoginKeyPad"

export const renderIfAuthenticated = (
  toRender: React.ComponentClass | (() => JSX.Element),
  dispatch: Dispatch<any>,
  dontCheck?: boolean
) => {
  const R = toRender
  if (!dontCheck) {
    const client = new BaseClient(imgBackEnd)
    // eslint-disable-next-line
    const r = /(token\/)([^\/&\+#\?]*)/i

    const loadOrGoToSMW = () => {
      GetUserInfo(
        client,
        () => {
          dispatch({ type: SET_AUTHORIZED, payload: true })
          ReactDOM.render(<R />, document.getElementById("root"))
        },
        () => (window.location.href = imgBackEnd)
      )
    }

    const p = new Promise<string>(async (res, rej) => {
      const regexRes = r.exec(window.location.href)
      let token: string | undefined
      const cache = await caches.open("taycan-simulation")
      if (regexRes) {
        token = regexRes[2]
        if (cache)
          cache.put("/token", new Response(token)).then(
            () => console.log("ok"),
            (r) => console.log(r)
          )
      }
      if (!token && cache) {
        const response = await cache.match("/token")
        if (response) {
          token = await response.text()
        }
      }
      if (token) res(token)
      else rej("could not load token")
    })
    p.then(
      (token) => {
        client.token = token
        RenewCookie(client, client.token,
          loadOrGoToSMW,
          () => (window.location.href = imgBackEnd)
        )
      },
      loadOrGoToSMW
    )
  } else {
    ReactDOM.render(<R />, document.getElementById("root"))
  }
}

const lcmapper = (state: AppState) => ({ authorized: state.mainState.authorized })
interface LoginComponentProps {
  authorized: boolean
  dispatch: Dispatch<any>
  children: JSX.Element
  skip?: boolean
}

const InternalLoginComponent = React.memo((props: LoginComponentProps) => {
  if (props.skip) props.dispatch({ type: SET_AUTHORIZED, payload: true })
  if (props.authorized) return props.children
  else return <LoginKeyPad />
})

export const LoginComponent = connect(lcmapper)(InternalLoginComponent)
