import { ENavigationOverlay } from "./navigationOverlay/NavigationOverlay"
import { RouteMonitorEvent } from "./routeMonitor/routeMonitorBubble/types"

export const START_NAVIGATION = "START_NAVIGATION"
export const SET_NAVIGATION_ROUTE = "SET_NAVIGATION_ROUTE"
export const STOP_NAVIGATION = "STOP_NAVIGATION"
export const UPDATE_NAVIGATION = "UPDATE_NAVIGATION"
export const INIT_NAVIGATION = "INIT_NAVIGATION"
export const UPDATE_MAP_INITILIZED = "UPDATE_MAP_INITILIZED"
export const UPDATE_MAP_ROUTE_OVERVIEW = "UPDATE_MAP_ROUTE_OVERVIEW"
export const UPDATE_DESTINATION_INPUT_ACTIVE = "UPDATE_DESTINATION_INPUT_ACTIVE"
export const EXPAND_ROUTE_MONITOR = "EXPAND_ROUTE_MONITOR"
export const SHOW_NO_ROUTES = "SHOW_NO_ROUTES"
export const SWITCH_MAP_STYLE = "SWITCH_MAP_STYLE"
export const CHANGE_NAVIGATION_OVERLAY = "CHANGE_NAVIGATION_OVERLAY"
export const SET_ACTIVE_POI_ITEM = "SET_ACTIVE_POI_ITEM"
export const UPDATE_POI_STACK_LIST_VISIBLE = "UPDATE_POI_STACK_LIST_VISIBLE"
export const ACTIVATE_ALTERNATIVE_ROUTES_OVERVIEW = "ACTIVATE_ALTERNATIVE_ROUTES_OVERVIEW"
export const ACTIVATE_DEST_INPUT_OVERVIEW = "ACTIVATE_DEST_INPUT_OVERVIEW"
export const UPDATE_SEARCH_AREA_SCREEN_VISIBILITY = "UPDATE_SEARCH_AREA_SCREEN_VISIBILITY"
export const UPDATE_SHOW_ALTERNATIVE_ROUTES = "UPDATE_SHOW_ALTERNATIVE_ROUTES"
export const SET_SELECTED_EVENT = "SET_SELECTED_EVENT"
export const UPDATE_SHOW_MINIMIZE_MAP_BUTTON = "UPDATE_SHOW_MINIMIZE_MAP_BUTTON"
export const SET_RANGE_MODE_ACTIVE = "SET_RANGE_MODE_ACTIVE"
export type ReduxInitAction = { type: "@@INIT" }

export type startNavigationAction = {
  type: typeof START_NAVIGATION
}

export type setNavigationRoutePayload = {
  routeId: string
}
export type setNavigationRouteAction = {
  type: typeof SET_NAVIGATION_ROUTE
  payload: setNavigationRoutePayload
}
export type stopNavigationAction = {
  type: typeof STOP_NAVIGATION
}

export type updateMapInitializedPayload = {
  isMapInitialized: boolean
}

export type updateMapInitializedAction = {
  type: typeof UPDATE_MAP_INITILIZED
  payload: updateMapInitializedPayload
}

export type updateMapRouteOverviewAction = {
  type: typeof UPDATE_MAP_ROUTE_OVERVIEW
  payload: boolean
}

export type updateDestinationInputActiveAction = {
  type: typeof UPDATE_DESTINATION_INPUT_ACTIVE
  payload: boolean
}

export type expandRouteMonitor = {
  type: typeof EXPAND_ROUTE_MONITOR
  payload: boolean
}

export type updateShowNoRoutesAction = {
  type: typeof SHOW_NO_ROUTES
  payload: boolean
}

export type updateShowAlternativeRoutesAction = {
  type: typeof UPDATE_SHOW_ALTERNATIVE_ROUTES
  payload: boolean
}

export type switchMapStyleAction = {
  type: typeof SWITCH_MAP_STYLE
}

export type changeNavigationOverlay = {
  type: typeof CHANGE_NAVIGATION_OVERLAY
  payload: ENavigationOverlay
}

export type setActivePoiItem = {
  type: typeof SET_ACTIVE_POI_ITEM
  payload: number
}

export type updatePoiStackListVisibleAction = {
  type: typeof UPDATE_POI_STACK_LIST_VISIBLE
  payload: boolean
}

export type activateAlternativeRoutesOverview = {
  type: typeof ACTIVATE_ALTERNATIVE_ROUTES_OVERVIEW
}
export type activateDestInputOverview = {
  type: typeof ACTIVATE_DEST_INPUT_OVERVIEW
}

export type updateSearchAreaScreenVisibility = {
  type: typeof UPDATE_SEARCH_AREA_SCREEN_VISIBILITY
  visible: boolean
}

export type setSelectedEvent = {
  type: typeof SET_SELECTED_EVENT
  payload: RouteMonitorEvent
}
export type updateShowMinimizeMapButton = {
  type: typeof UPDATE_SHOW_MINIMIZE_MAP_BUTTON
  show: boolean
}

export type setRangeModeActive = {
  type: typeof SET_RANGE_MODE_ACTIVE
  payload: boolean
}

export type updateNavigationPayload = {
  currentPosition: [number, number]
  nextManeuver: ManeuverType
  remainingDistance: number
  remainingDuration: number
  bearing: number
  speed: number
  acceleration?: number
}

export type updateNavigationAction = {
  type: typeof UPDATE_NAVIGATION
  payload: updateNavigationPayload
}
export type initNavigationPayload = {
  alternativeRoutes: Route[]
  activeRouteId: string
  currentPosition: [number, number]
  bearing: number
  speed: number
}
export type initNavigationAction = {
  type: typeof INIT_NAVIGATION
  payload: initNavigationPayload
}

type TurnguidanceeventType = "continue" | "turn" | "roundabout" | "arrive" | "depart"

export interface TravelData {
  rrd?: number
  eta?: number
  rtt?: number
  soc?: TravelDataSoc
  trafficDelay?: number
}
export enum TravelDataSocState {
  Available = "Available",
  Calculating = "Calculating"
}

export interface TravelDataSoc {
  value: number
  state: TravelDataSocState
}
export type ManeuverType = {
  location: number[]
  type: TurnguidanceeventType
  modifier?: "continue" | "left" | "slight left" | "right" | "slight right" | "uturn" | "destination" | "stopover"
}

export enum DriveMode {
  Individual = "Individual",
  Normal = "Normal",
  Range = "Range",
  Sport = "Sport",
  SportPlus = "Sport Plus"
}

export enum NaviClimateSetting {
  Eco = "NavigationScreen_Climate_Setting_ECO",
  EcoAcOff = "NavigationScreen_Climate_Setting_ECO_AC_OFF",
  FanOnly = "NavigationScreen_Climate_Setting_Fan_Only",
  NoClimateLimit = "NavigationScreen_Climate_Setting_No_Climate_Limit",
  Off = "NavigationScreen_Climate_Setting_OFF"
}

export type Route = {
  id: string
  totalDistance: number
  totalDuration: number
  route: number[][]
  bounds: number[][] | undefined
  travelData?: TravelData
  climateSetting?: NaviClimateSetting
  // speedLimit?: SpeedType
  driveMode?: DriveMode
}

export type NavigationState = {
  alternativeRoutes: Route[]
  activeRouteId: string | undefined
  remainingDistance: number | undefined
  remainingDuration: number | undefined
  isActive: boolean
  currentPosition: [number, number]
  nextManeuver: ManeuverType | undefined
  bearing: number
  speed: number
}

export type MapState = {
  isMapInitialized: boolean
  isRouteOverViewActive: boolean
  style: string
  showNoRoutes: boolean
  showAlternativeRoutes: boolean
  isDestinationInputActive: boolean
  isSearchAreaScreenVisible: boolean
  showMinimizeMapButton: boolean
  selectedEvent: RouteMonitorEvent | undefined
  expandRouteMonitor: boolean
  rangeModeActive: boolean
}

export type PoiState = {
  overlay: ENavigationOverlay
  activeItemIndex: number | undefined
  poiStackListVisible: boolean
}

export type NavigationActionType =
  | startNavigationAction
  | setNavigationRouteAction
  | stopNavigationAction
  | updateNavigationAction
  | initNavigationAction

export type MapActionType =
  | updateMapInitializedAction
  | updateDestinationInputActiveAction
  | updateMapRouteOverviewAction
  | switchMapStyleAction
  | updateShowNoRoutesAction
  | startNavigationAction
  | stopNavigationAction
  | activateAlternativeRoutesOverview
  | updateShowAlternativeRoutesAction
  | activateDestInputOverview
  | updateSearchAreaScreenVisibility
  | setSelectedEvent
  | updateShowMinimizeMapButton
  | expandRouteMonitor
  | setRangeModeActive

export type PoiActionType =
  | changeNavigationOverlay
  | setActivePoiItem
  | updatePoiStackListVisibleAction
  | activateDestInputOverview
