import {
  SongActionType,
  setCurrentBrowsingCategoryUSBAction,
  setCurrentBrowsingCategory_AppleAction,
  setCurrentBrowsingCategory_ApplePodcastsAction,
  disableEntryAnimationsAction,
  audioPlayerState,
  SET_SONG,
  song,
  PLAY_SONG,
  SET_CURRENT_BROWSING_CATEGORY_USB,
  DISABLE_ENTRY_ANIMATIONS,
  SET_CURRENT_BROWSING_CATEGORY_APPLE,
  SET_CURRENT_BROWSING_CATEGORY_APPLE_PODCASTS,
  TRIGGER_LIST_SWITCH_ANIMATION,
  trigglerListSwitchAnimationAction,
  setCurrentBrowsingCategory_SiriusXMAction,
  SET_CURRENT_BROWSING_CATEGORY_SIRIUS_XM
} from "@pag/center/components/player/audioPlayer/types"
import { ESourceNames } from "@pag/center/components/quickFilter/quickFilterIconWithSpeaker/types"
import {
  songListRadio,
  songListUSB,
  songListOnlineRadio,
  podcasts
} from "@pag/center/components/player/audioPlayer/helper"

const initialState: audioPlayerState = {
  songs_USB: songListUSB,
  songs_radio: songListRadio,
  songs_online_radio: songListOnlineRadio,
  podcasts: podcasts,
  playing: false,
  currentBrowsingCategory_USB: { name: "Overview", categoryType: "Overview", label: "Overview" },
  currentBrowsingCategory_Apple: { name: "Overview_Apple_Music", categoryType: "Overview", label: "Overview" },
  currentBrowsingCategory_ApplePodcasts: {
    name: "Overview_Apple_Podcasts",
    categoryType: "Overview",
    label: "Overview"
  },
  currentBrowsingCategory_SiriusXM: { name: "Overview_Sirius_XM", categoryType: "Overview", label: "Overview" },
  entryAnimationsDisabled: false,
  listFilter: "",
  listSwitchAnimation: false
}

function updateDisplayedSong(array: song[], action: SongActionType) {
  return array.map((song: song, index: number) => {
    if (action.song.title !== song.title) {
      if (song.displayed)
        return {
          artist: song.artist,
          title: song.title,
          album: song.album,
          cover: song.cover,
          playing: false,
          displayed: false,
          filename: song.filename,
          genre: song.genre
        }
      else return song
    }
    return {
      ...song,
      ...action.song
    }
  })
}

function playDisplayedSong(array: song[], action: SongActionType) {
  return array.map((song: song, index: number) => {
    if (action.song.title !== song.title) {
      return song
    }
    return {
      ...song,
      ...action.song
    }
  })
}

export function songReducer(
  state = initialState,
  action:
    | SongActionType
    | setCurrentBrowsingCategoryUSBAction
    | disableEntryAnimationsAction
    | setCurrentBrowsingCategory_AppleAction
    | setCurrentBrowsingCategory_ApplePodcastsAction
    | setCurrentBrowsingCategory_SiriusXMAction
    | trigglerListSwitchAnimationAction
): audioPlayerState {
  switch (action.type) {
    case SET_SONG:
      switch (action.source) {
        case ESourceNames.USB:
          return {
            ...state,
            songs_USB: updateDisplayedSong(state.songs_USB, action),
            playing: action.playing,
            listFilter: action.listFilter
          }

        case ESourceNames.ONLINE_RADIO:
          return {
            ...state,
            songs_online_radio: updateDisplayedSong(state.songs_online_radio, action),
            playing: action.playing,
            listFilter: action.listFilter
          }

        case ESourceNames.RADIO:
          return { ...state, songs_radio: updateDisplayedSong(state.songs_radio, action), playing: action.playing }

        case ESourceNames.APPLE_MUSIC_RADIO:
          return {
            ...state,
            songs_online_radio: updateDisplayedSong(state.songs_online_radio, action),
            playing: action.playing,
            listFilter: action.listFilter
          }
        case ESourceNames.APPLE_MUSIC:
          return {
            ...state,
            songs_USB: updateDisplayedSong(state.songs_USB, action),
            playing: action.playing,
            listFilter: action.listFilter
          }
        case ESourceNames.APPLE_PODCASTS:
          return {
            ...state,
            podcasts: updateDisplayedSong(state.podcasts, action),
            playing: action.playing,
            listFilter: action.listFilter
          }
        default:
          return {
            ...state,
            songs_USB: updateDisplayedSong(state.songs_USB, action),
            playing: action.playing,
            listFilter: action.listFilter
          }
      }

    case PLAY_SONG:
      switch (action.source) {
        case ESourceNames.USB:
          return { ...state, songs_USB: playDisplayedSong(state.songs_USB, action), playing: action.playing }

        case ESourceNames.ONLINE_RADIO:
          return {
            ...state,
            songs_online_radio: playDisplayedSong(state.songs_online_radio, action),
            playing: action.playing
          }

        case ESourceNames.RADIO:
          return { ...state, songs_radio: playDisplayedSong(state.songs_radio, action), playing: action.playing }

        default:
          return { ...state, songs_USB: playDisplayedSong(state.songs_USB, action), playing: action.playing }
      }
    case SET_CURRENT_BROWSING_CATEGORY_USB:
      return { ...state, currentBrowsingCategory_USB: action.category }
    case SET_CURRENT_BROWSING_CATEGORY_APPLE:
      return { ...state, currentBrowsingCategory_Apple: action.category }
    case SET_CURRENT_BROWSING_CATEGORY_APPLE_PODCASTS:
      return { ...state, currentBrowsingCategory_ApplePodcasts: action.category }
    case SET_CURRENT_BROWSING_CATEGORY_SIRIUS_XM:
      return { ...state, currentBrowsingCategory_SiriusXM: action.category }
    case DISABLE_ENTRY_ANIMATIONS:
      return { ...state, entryAnimationsDisabled: action.disabled }
    case TRIGGER_LIST_SWITCH_ANIMATION:
      return { ...state, listSwitchAnimation: action.animation }
    default:
      return state
  }
}
