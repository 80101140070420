import { song } from "./types"
//covers
import webtunes from "@pag/center/assets/mp3/covers/webtunes_vol3.png"
import webtunes2 from "@pag/center/assets/mp3/covers/webtunes_vol4.png"
import bbc1 from "@pag/center/assets/mp3/radio/covers/bbc1_cover.png"
import bbc2 from "@pag/center/assets/mp3/radio/covers/bbc2_cover.png"
import bytefm from "@pag/center/assets/mp3/radio/covers/bytefm_cover.png"
import fm4 from "@pag/center/assets/mp3/radio/covers/fm4_cover.png"
import swr2 from "@pag/center/assets/mp3/radio/covers/swr2_cover.png"
import swr3 from "@pag/center/assets/mp3/radio/covers/swr3_cover.png"
import swr4 from "@pag/center/assets/mp3/radio/covers/swr4_cover.png"
import podcast from "@pag/center/assets/mp3/podcasts/covers/podcast_cover.jpg"

export const songListUSB: song[] = [
  {
    artist: "Various Artists",
    title: "Radically You",
    album: "Porsche Webtunes Vol.3 - Cayman",
    displayed: true,
    cover: webtunes,
    filename: "sign_here.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Pushing Boundaries",
    album: "Porsche Webtunes Vol.3 - Cayman",
    displayed: false,
    cover: webtunes,
    filename: "afro_blue.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Flat-6",
    album: "Porsche Webtunes Vol.3 - Cayman",
    displayed: false,
    cover: webtunes,
    filename: "along_the_ocean.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Peridot Shimmer",
    album: "Porsche Webtunes Vol.3 - Cayman",
    displayed: false,
    cover: webtunes,
    filename: "drive.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Bpm sets Rpm",
    album: "Porsche Webtunes Vol.3 - Cayman",
    displayed: false,
    cover: webtunes,
    filename: "life_intensified.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Depth-sounder",
    album: "Porsche Webtunes Vol.3 - Cayman",
    displayed: false,
    cover: webtunes,
    filename: "one.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Complete",
    album: "Porsche Webtunes Vol.3 - Cayman",
    displayed: false,
    cover: webtunes,
    filename: "ambient.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Moonlight Ride",
    album: "Porsche Webtunes Vol.4 - Boxter",
    displayed: false,
    cover: webtunes2,
    filename: "moonlight_drive.mp3",
    genre: "Pop"
  },
  {
    artist: "Various Artists",
    title: "Across The Space",
    album: "Porsche Webtunes Vol.4 - Boxter",
    displayed: false,
    cover: webtunes2,
    filename: "across_the_space.mp3",
    genre: "Pop"
  },
  {
    artist: "Various Artists",
    title: "Along The Ocean",
    album: "Porsche Webtunes Vol.4 - Boxter",
    displayed: false,
    cover: webtunes2,
    filename: "along_the_ocean.mp3",
    genre: "Pop"
  },
  {
    artist: "Various Artists",
    title: "Unleashed",
    album: "Porsche Webtunes Vol.4 - Boxter",
    displayed: false,
    cover: webtunes2,
    filename: "unleashed.mp3",
    genre: "Pop"
  },
  {
    artist: "Various Artists",
    title: "Evening Cruise",
    album: "Porsche Webtunes Vol.4 - Boxter",
    displayed: false,
    cover: webtunes2,
    filename: "to_the_sea.mp3",
    genre: "Pop"
  },
  {
    artist: "Various Artists",
    title: "Independence Day",
    album: "Porsche Webtunes Vol.4 - Boxter",
    displayed: false,
    cover: webtunes2,
    filename: "independence_day.mp3",
    genre: "Pop"
  }
]

export const songListRadio: song[] = [
  { title: "SWR 3", artist: "", album: "", displayed: true, cover: swr3, filename: "bbc1.mp3", genre: "Rock" },
  { title: "SWR 2", artist: "", album: "", displayed: false, cover: swr2, filename: "bbc2.mp3", genre: "Hits" },
  { title: "SWR 4", artist: "", album: "", displayed: false, cover: swr4, filename: "bytefm.mp3", genre: "Charts" }
]

export const songListOnlineRadio: song[] = [
  {
    title: "Byte FM",
    artist: "",
    album: "",
    displayed: true,
    cover: bytefm,
    filename: "bytefm.mp3",
    genre: "Rock"
  },
  { title: "BBC 1", artist: "", album: "", displayed: false, cover: bbc1, filename: "bbc1.mp3", genre: "Hits" },
  { title: "BBC 2", artist: "", album: "", displayed: false, cover: bbc2, filename: "bbc2.mp3", genre: "Classic" },
  { title: "FM 4", artist: "", album: "", displayed: false, cover: fm4, filename: "bbc1.mp3", genre: "Charts" }
]

export const podcasts: song[] = [
  {
    title: "About leaders who care",
    artist: "Next Visions - Today's Masterminds about Topics of Tomorro‪w‬",
    album: "",
    displayed: true,
    cover: podcast,
    filename: "podcast.mp3",
    genre: "Society & Culture"
  },
  {
    title: "About intersectionality",
    artist: "Next Visions - Today's Masterminds about Topics of Tomorro‪w‬",
    album: "",
    displayed: false,
    cover: podcast,
    filename: "podcast_about_intersectionality_1.mp3",
    genre: "Technology"
  },
  {
    title: "About next rolemodels",
    artist: "Next Visions - Today's Masterminds about Topics of Tomorro‪w‬",
    album: "",
    displayed: false,
    cover: podcast,
    filename: "podcast_about_next_role_models_1.mp3",
    genre: "Science"
  },
  {
    title: "About new currencies",
    artist: "Next Visions - Today's Masterminds about Topics of Tomorro‪w‬",
    album: "",
    displayed: false,
    cover: podcast,
    filename: "podcast__about_new_currencies_1.mp3 ",
    genre: "Science"
  }
]

export const favourtiesRadios: { [key: string]: any } = {
  fm4: fm4,
  swr3: swr3,
  swr2: swr2,
  swr4: swr4
}
