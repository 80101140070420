import {
  LauncherTileState,
  LauncherTile,
  LauncherTileActionType,
  UPDATE_LAUNCHER_TILE_ORDER,
  SHOW_SUGGESTIONS
} from "./types"
import * as lists from "@pag/center/views/settingsScreen/listContents/settingsListContents"

const smallTileList: LauncherTile[] = [
  { text: "CenterMainScreen_Tiles_Charge", iconType: "icon-charging_active", url: "/center/charging" },
  { text: "CenterMainScreen_Tiles_AC", iconType: "icon-climate", url: "/center/climate" },
  { text: "CenterMainScreen_Tiles_Service", iconType: "icon-predictive_maintenance", url: "/center/maintenance" },
  { text: "CenterMainScreen_Tiles_Weather", iconType: "icon-weather", url: "/center/weather" },
  { text: "CenterMainScreen_Tiles_Devices", iconType: "icon-connect", url: "/center/devices" },
  { text: "CenterMainScreen_Tiles_SportChrono", iconType: "icon-timer", url: "/center/sport-chrono" },
  { text: "CenterMainScreen_Tiles_Messages", iconType: "icon-message_outline", url: "/center/messages" },
  { text: "CenterMainScreen_Tiles_Updates", iconType: "icon-update_center", url: "/center/updates" },
  { text: "CenterMainScreen_Tiles_HomeLink", iconType: "icon-homelink", url: "/center/homelink" },
  { text: "CenterMainScreen_Tiles_News", iconType: "icon-news", url: "/center/news" },
  { text: "CenterMainScreen_Tiles_Calendar", iconType: "icon-calendar", url: "/center/calendar" },
  { text: "Info", iconType: "icon-information_outline", settings_list: lists.Settings_Info }
  // { text: "eCall", iconType: "icon-road_side_assistance", url: "/center/phone" }
]

const launcherTileInitialState: LauncherTileState = {
  tiles: smallTileList,
  showSuggestions: true
}

export function launcherTileReducer(
  state = launcherTileInitialState,
  action: LauncherTileActionType
): LauncherTileState {
  switch (action.type) {
    case UPDATE_LAUNCHER_TILE_ORDER:
      return {
        ...state,
        tiles: action.payload.tiles
      }
    case SHOW_SUGGESTIONS:
      return {
        ...state,
        showSuggestions: action.payload
      }
    default:
      return state
  }
}
