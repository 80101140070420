import {
  NavigationActionType,
  NavigationState,
  START_NAVIGATION,
  STOP_NAVIGATION,
  UPDATE_NAVIGATION,
  INIT_NAVIGATION,
  MapState,
  MapActionType,
  UPDATE_MAP_INITILIZED,
  UPDATE_MAP_ROUTE_OVERVIEW,
  SWITCH_MAP_STYLE,
  SET_NAVIGATION_ROUTE,
  PoiState,
  PoiActionType,
  CHANGE_NAVIGATION_OVERLAY,
  SET_ACTIVE_POI_ITEM,
  UPDATE_POI_STACK_LIST_VISIBLE,
  SHOW_NO_ROUTES,
  UPDATE_DESTINATION_INPUT_ACTIVE,
  ACTIVATE_ALTERNATIVE_ROUTES_OVERVIEW,
  ACTIVATE_DEST_INPUT_OVERVIEW,
  UPDATE_SEARCH_AREA_SCREEN_VISIBILITY,
  UPDATE_SHOW_ALTERNATIVE_ROUTES,
  SET_SELECTED_EVENT,
  UPDATE_SHOW_MINIMIZE_MAP_BUTTON,
  EXPAND_ROUTE_MONITOR,
  SET_RANGE_MODE_ACTIVE
} from "./types"

import { ENavigationOverlay } from "./navigationOverlay/NavigationOverlay"
import NavigationService from "@pag/utils/navigationService/NavigationService"
import { setRangeModeActive } from "./actions"

const initialNavigationState: NavigationState = {
  alternativeRoutes: [],
  activeRouteId: undefined,
  currentPosition: NavigationService.getActiveConfig().initialPosition,
  isActive: false,
  bearing: 0,
  speed: 0,
  nextManeuver: undefined,
  remainingDistance: undefined,
  remainingDuration: undefined
}

const initialMapState: MapState = {
  isMapInitialized: false,
  isRouteOverViewActive: true,
  style: "day",
  showNoRoutes: true,
  isDestinationInputActive: false,
  isSearchAreaScreenVisible: false,
  showAlternativeRoutes: false,
  selectedEvent: undefined,
  showMinimizeMapButton: false,
  expandRouteMonitor: true,
  rangeModeActive: false
}

const initialPoiState: PoiState = {
  overlay: ENavigationOverlay.GUIDANCE_ACTIVE,
  activeItemIndex: undefined,
  poiStackListVisible: false
}

export function navigationReducer(state = initialNavigationState, action: NavigationActionType): NavigationState {
  switch (action.type) {
    case START_NAVIGATION: {
      if (state.activeRouteId) {
        NavigationService.start(state.activeRouteId)
      }
      return {
        ...state,
        isActive: true
      }
    }

    case SET_NAVIGATION_ROUTE:
      return {
        ...state,
        activeRouteId: action.payload.routeId
      }

    case STOP_NAVIGATION:
      return {
        ...state,
        currentPosition: initialNavigationState.currentPosition,
        isActive: initialNavigationState.isActive,
        bearing: initialNavigationState.bearing,
        speed: initialNavigationState.speed,
        nextManeuver: initialNavigationState.nextManeuver,
        remainingDistance: initialNavigationState.remainingDistance,
        remainingDuration: initialNavigationState.remainingDuration
      }
    case UPDATE_NAVIGATION:
      return {
        ...state,
        currentPosition: action.payload.currentPosition,
        bearing: action.payload.bearing,
        speed: action.payload.speed,
        isActive: true,
        remainingDistance: action.payload.remainingDistance,
        nextManeuver: action.payload.nextManeuver,
        remainingDuration: action.payload.remainingDuration
      }
    case INIT_NAVIGATION:
      return {
        ...state,
        activeRouteId: action.payload.activeRouteId,
        alternativeRoutes: action.payload.alternativeRoutes,
        currentPosition: action.payload.currentPosition,
        bearing: action.payload.bearing,
        speed: action.payload.speed
      }
    default:
      return state
  }
}

export function mapReducer(state = initialMapState, action: MapActionType): MapState {
  switch (action.type) {
    case UPDATE_MAP_INITILIZED:
      return {
        ...state,
        isMapInitialized: action.payload.isMapInitialized
      }
    case UPDATE_MAP_ROUTE_OVERVIEW:
      return {
        ...state,
        isRouteOverViewActive: action.payload
      }
    case START_NAVIGATION:
      return {
        ...state,
        isRouteOverViewActive: false,
        isDestinationInputActive: false,
        showAlternativeRoutes: false
      }
    case STOP_NAVIGATION:
      return {
        ...state,
        isRouteOverViewActive: true,
        showAlternativeRoutes: false,
        showNoRoutes: true,
        selectedEvent: undefined
      }
    case SWITCH_MAP_STYLE:
      return {
        ...state,
        style: state.style === "day" ? "night" : "day"
      }
    case SHOW_NO_ROUTES: {
      return {
        ...state,
        showNoRoutes: action.payload
      }
    }
    case UPDATE_DESTINATION_INPUT_ACTIVE: {
      return {
        ...state,
        isDestinationInputActive: action.payload
      }
    }
    case ACTIVATE_ALTERNATIVE_ROUTES_OVERVIEW: {
      return {
        ...state,
        isDestinationInputActive: false,
        isRouteOverViewActive: true,
        showNoRoutes: false,
        showAlternativeRoutes: true
      }
    }
    case ACTIVATE_DEST_INPUT_OVERVIEW: {
      return {
        ...state,
        isDestinationInputActive: true,
        showNoRoutes: true,
        showAlternativeRoutes: false
      }
    }
    case UPDATE_SHOW_ALTERNATIVE_ROUTES: {
      return {
        ...state,
        showAlternativeRoutes: action.payload
      }
    }
    case UPDATE_SEARCH_AREA_SCREEN_VISIBILITY: {
      return {
        ...state,
        isDestinationInputActive: !action.visible,
        isSearchAreaScreenVisible: action.visible
      }
    }
    case SET_SELECTED_EVENT: {
      return {
        ...state,
        selectedEvent: action.payload
      }
    }
    case UPDATE_SHOW_MINIMIZE_MAP_BUTTON: {
      return {
        ...state,
        showMinimizeMapButton: action.show
      }
    }
    case EXPAND_ROUTE_MONITOR: {
      return {
        ...state,
        expandRouteMonitor: action.payload
      }
    }
    case SET_RANGE_MODE_ACTIVE: {
      return {
        ...state,
        rangeModeActive: action.payload
      }
    }
    default:
      return state
  }
}

export function poiReducer(state = initialPoiState, action: PoiActionType): PoiState {
  switch (action.type) {
    case CHANGE_NAVIGATION_OVERLAY:
      return {
        ...state,
        overlay: action.payload
      }
    case SET_ACTIVE_POI_ITEM:
      return {
        ...state,
        activeItemIndex: action.payload
      }
    case UPDATE_POI_STACK_LIST_VISIBLE:
      return {
        ...state,
        poiStackListVisible: action.payload
      }
    case ACTIVATE_DEST_INPUT_OVERVIEW:
      return initialPoiState
    default:
      return state
  }
}
