import React, { lazy, Suspense } from "react"
import { DndProvider } from "react-dnd"
import TouchBackend from "react-dnd-touch-backend"
import { Provider } from "react-redux"
import configureStore from "./components/redux/reducer/index"
import { LoginComponent, renderIfAuthenticated } from "./imgConnection/Helpers"
import { MiniLoader } from "./imgConnection/MiniLoader"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import { availableLanguages } from "@pag/center/translations/availableLanguages"
const AppContainer = lazy(() => import("./AppContainer"))

const store = configureStore()
export type AppStoreType = typeof store

// eslint-disable-next-line
const getUrl = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/
const exec = getUrl.exec(document.location.href)
const curServer = exec ? exec : ["", "localhost"]
export const srv = curServer[1].toLowerCase()
export const isSMW = srv.indexOf("pcm.smart-mobility.info") >= 0 || srv.indexOf("pcm-cl33.smart-mobility.info") >= 0
export const isFT = srv.indexOf("pcm.feature-testing.com") >= 0 || srv.indexOf("taycan-pcm.azurewebsites.net") >= 0;
export const isLH = srv.indexOf("localhost") >= 0
if (isSMW || isFT || isLH) {
  const Root = () => (
    <Provider store={store}>
      <LoginComponent skip={false}>
        <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
          <AppContainer />
        </DndProvider>
      </LoginComponent>
    </Provider>
  )

  const Startup = () => (
    <Suspense fallback={<MiniLoader />}>
      <Root />
    </Suspense>
  )

  const skipChecking = srv.indexOf("pcm.smart-mobility.info") < 0 && srv.indexOf("pcm-cl33.smart-mobility.info") < 0

  const locale = localStorage.getItem("locale")
  if (
    window.location.search.split("locale=")[1] !== locale &&
    availableLanguages.includes(window.location.search.split("locale=")[1])
  ) {
    localStorage.setItem("locale", window.location.search.split("locale=")[1])
    window.location.reload()
  }

  renderIfAuthenticated(Startup, store.dispatch, skipChecking)
  //renderIfSimpleLogin(Startup, store, true);
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
}
