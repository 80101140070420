import React, { useState } from "react"
import ClassNames from "classnames"

import "./baseListItemSeparateToucharea.scss"

interface IBaseListItemSeparateToucharea {
  centerEndNeedsPadding?: boolean
  start?: React.ReactNode
  centerStart?: React.ReactNode
  centerEnd?: React.ReactNode
  end?: React.ReactNode
  hideStart?: boolean
  hideCenterEnd?: boolean
  hideEnd?: boolean
  active?: boolean
  disableTouchAnimation?: boolean
  multiline?: boolean
  // focusIgnore?: boolean
  isNaviAndAsia?: boolean
  onTap?: Function
  onTapEnd?: Function
}

const BaseListItemSeparateToucharea: React.FC<IBaseListItemSeparateToucharea> = (
  props: IBaseListItemSeparateToucharea
) => {
  const [pressed, setPressed] = useState(false)
  const [endPressed, setEndPressed] = useState(false)

  return (
    <div className="base-list-item-separate-toucharea-wrapper">
      <div
        className={ClassNames(
          "list-item list-item--scroll-snap",
          { "list-item--singleline": !props.multiline && !props.isNaviAndAsia },
          { "list-item--multiline-asia": !props.multiline && props.isNaviAndAsia },
          { "list-item--multiline": props.multiline },
          { "state-active": props.active }
        )}
      >
        <div
          className={ClassNames({ "state-pressed": pressed }, "list-touchable", "list-touchable--full")}
          onMouseDown={() => setPressed(!pressed)}
          onMouseUp={() => setPressed(!pressed)}
          onClick={() => {
            props.onTap && props.onTap()
            setPressed(true)
            setTimeout(() => {
              setPressed(false)
            }, 500)
          }}
        >
          {!props.disableTouchAnimation && (
            <div className="list-touchable__animated-bg">
              {/* <div className="animated-bg--growing-circle"></div> */}
              <div className="animated-bg--pressed"></div>
            </div>
          )}

          {!props.hideStart && <div className="list-item-start">{props.start}</div>}

          <div className="list-item-separate-toucharea__center-content-wrapper">
            <div className="list-item-separate-toucharea__center-content-start">{props.centerStart}</div>
          </div>
          {!props.hideCenterEnd && (
            <div
              className={ClassNames(
                { "list-item-separate-toucharea__center-content-end--no-padding": !props.centerEndNeedsPadding },
                { "list-item-separate-toucharea__center-content-end--text-padding": props.centerEndNeedsPadding }
              )}
            >
              {props.centerEnd}
            </div>
          )}
        </div>

        <div
          className={ClassNames({ "state-pressed": endPressed }, "list-touchable")}
          onMouseDown={() => setEndPressed(!endPressed)}
          onMouseUp={() => setEndPressed(!endPressed)}
          onClick={() => {
            props.onTapEnd && props.onTapEnd()
            setEndPressed(true)
            setTimeout(() => {
              setEndPressed(false)
            }, 500)
          }}
        >
          {!props.disableTouchAnimation && (
            <div className="list-touchable__animated-bg">
              {/* <div className="animated-bg--growing-circle"></div> */}
              <div className="animated-bg--pressed"></div>
            </div>
          )}

          {/* {!props.hideEnd && !isLoading && ( */}
          {!props.hideEnd && <div className="list-item-end list-item-end__touch-divider">{props.end}</div>}

          {/* {isLoading && <div className="list-item-end list-item-end__touch-divider"><pag3-loading-spinner /></div>} */}
        </div>
      </div>
    </div>
  )
}

export default BaseListItemSeparateToucharea
