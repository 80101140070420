import React from "react"
import cx from "classnames"
import "./blockableContainer.scss"
import Icon from "../icon/Icon"
import { IconType } from "../icon/iconType"
import I18nLabel from "../I18nLabel/I18nLabel"

interface BlockableContainerProps {
  isFlat?: boolean
  isTransparent?: boolean
  isFullTransparent?: boolean
  className?: string
  blocked?: boolean
}

const BlockableContainer: React.FC<BlockableContainerProps> = (props) => {
  return (
    <div className={cx("blockable-container-wrapper", props.className)}>
      {props.children}
      {props.blocked ? (
        <div
          className={cx(
            "overlay",
            { "overlay--flat": props.isFlat },
            { "overlay--transparent": props.isTransparent },
            { "overlay--full-transparent": props.isFullTransparent }
          )}
        >
          <div className="overlay__container">
            <Icon iconClass="overlay__icon" iconType={IconType.icon_circle_crossed} />
            <I18nLabel className="overlay__text" text="Limited functionality while driving"></I18nLabel>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default BlockableContainer
